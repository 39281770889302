$form_border: #dcdcdc;


input[type="text"],
input[type="email"],
textarea{
    padding: 0.5em 0.8em;
    display: block;
    width: 100%;
    border: 1px solid #b8b8b8;
}
textarea{
    min-height: 280px;
}

.required{
    color: $red;
    padding-left: 0.3em;
}

.wrap-contact-form{
    border-top: 1px solid $form_border;
}
.contact-form-group{
    max-width: 780px;
    margin: 0px auto;
    display: flex;
    border-top: 1px solid $form_border;
    &:first-child{
        border-top: none;
    }
    &--title,
    &--body{
        padding: 35px 0px;
    }
    &--title{
        width: 11em;
        padding-top: calc(35px + 0.5em);
    }
    &--body{
        width: calc(100% - 11.5em);
    }
    &.type-privacypolicy{
        border: none;
        display: block;
    }
}
body.confirm{
    .contact-form-group{
        align-items: center;
    }
}
.contact-annotation{
    margin-top: 0.7em;
}

//privacypolicy
.box-privacypolicy{
    border: 5px solid $form_border;
    padding: 10px 35px;
    margin-bottom: 2em;
    &--title{
        margin: 0px 0px 1.5em 0px;
        padding: 0.5em 1em 1em 1em;
        font-size: rem(22);
        color: #1a2c54;
        border-bottom: 1px solid $form_border;
        text-align: center;
    }
    p{
        margin-bottom: 2em;
    }
}
.check-privacypolicy{
    padding-bottom: 3em;
    text-align: center;
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 1.95em;
        cursor: pointer;
    }
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -0.62em;
        width: 1.24em;
        height: 1.24em;
        border: 3px solid $form_border;
        background: #fff;
    }
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 20px;
        height: 10px;
        border-bottom: 3px solid $blue;
        border-left: 3px solid $blue;
        transition: all .2s;
        transform: rotate(-45deg);
    }
    [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        // transform: scale(0);
    }
    [type="checkbox"]:checked + label:after {
        opacity: 1;
        // transform: scale(1);
    }
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
        border: 2px dotted blue;
    }
    label:hover:before {
        border: 2px solid #4778d9!important;
    }
}

.btn-form{
    border-radius: 0;
    -webkit-box-sizing: content-box;
    -webkit-appearance: button;
    appearance: button;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    font-size: rem(20);
    background-color: $blue;
    color: #fff;
    margin: 0px 5px 10px 5px;
    padding: 0.6em 3em;
    min-width: 240px;
    transition: all .25s ease-out;
    position: relative;
    &:after{
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -7px;
        transform: rotate(45deg);
    }
    &:hover, &:focus{
        opacity: 0.5;
    }
    &[disabled]{
        opacity: 0.1;
        cursor: default;
        background-color: #333;
    }
    &.type-back{
        background-color: #959595;
        &:after{
            right: auto;
            left: 15px;
            transform: rotate(-135deg);
        }
    }
}