.btn-arrow,
a.btn-arrow{
    display: inline-block;
    padding-left: 30px;
    text-decoration: none;
    font-size: rem(18);
    font-weight: bold;
    color: $gray-800;
    transition: all .25s ease-out;
    &:before{
        content: ">";
        padding-right: 5px;
    }
    &:hover, &:focus{
        opacity: 0.5;
    }
    &.color-blue{
        color: #1a2c54;
    }
}

.btn-arrow-box,
a.btn-arrow-box{
    width: 100%;
    max-width: 400px;
    display: inline-block;
    padding: 0.8em 2em;
    text-decoration: none;
    font-size: rem(20);
    color: #fff;
    background-color: $blue;
    transition: all .25s ease-out;
    position: relative;
    &:before{
        position: absolute;
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
        top: 50%;
        margin-top: -6px;
        right: 15px;
    }
    &:hover, &:focus{
        opacity: 0.5;
    }
}