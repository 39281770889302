.definition{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:not(.type-in){
        border-top: 1px solid #dcdcdc;
    }
    dt,dd{
        border-bottom: 1px solid #dcdcdc;
        padding: 1.3em 0px;
        ul, dl{
            &:last-of-type{
                margin: 0px;
            }
        }
    }
    dt{
        width: 6em;
        padding-right: 1em;
        color: $blue;
        @include media-breakpoint-down(xs) {
            width: 100%;
            padding-right: 0px;
            border-bottom: none;
            padding-bottom: 0px;
            br{
                display: none;
            }
        }
        &.type-title-wide{
            width: 10em;
            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }
    }
    dd{
        margin: 0px;
        width: calc(100% - 6em);
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
        &.type-title-wide{
            width: calc(100% - 10em);
            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }
    }
    &.type-in{
        dt{
            width: 14em;
            color: $body-color;
            @include media-breakpoint-down(sm) {
                width: 100%;
                border-bottom: none;
                padding-bottom: 0px;
            }
        }
        dd{
            margin: 0px;
            width: calc(100% - 14em);
            @include media-breakpoint-down(sm) {
                width: 100%;
                padding-top: 0px;
            }
        }
        dt, dd{
            &:first-of-type{
                padding-top: 0px;
            }
            &:last-of-type{
                border-bottom: none;
                padding-bottom: 0px;
            }
        }
    }
}
.definition + .definition-text{
    margin: 1.3em 0px 0px 0px;
    padding-top: 1.3em;
    border-top: 1px solid #dcdcdc;
}
.definition + .definition-text.type-cont{
    border-top: none;
    margin: 0px;
    padding-top: 0px;
    padding-bottom: 1.3em;
}
.definition-text + .definition{
    margin: 0px 0px 1.3em 0px;
    padding-top: 1.3em;
    border-top: 1px solid #dcdcdc;
}

.list-definition{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    dt, dd{
        margin-bottom: 1.3em;
    }
    dt{
        width: 9.5em;
        text-align: center;
        border: 1px solid #000000;
        @include media-breakpoint-down(sm) {
            margin-bottom: 0.5em;
        }
    }
    dd{
        width: calc(100% - 11em);
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}

.archive{
    max-width: 700px;
    padding: 0px;
    margin: 0px auto;
    &-item{
        margin: 0px;
        padding: 1.2em 0px;
        display: flex;
        border-bottom: 1px solid #dcdcdc;
    }
    &--time{
        flex-grow: 0;
        padding-right: 20px;
        flex-shrink: 0;
    }
    &--title{
        flex-grow: 1;
    }
}

.list-banner{
    padding: 0px;
    margin: 0px -10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &--item{
        list-style: none;
        padding: 0px 10px 20px 10px;
        min-width: 150px;
    }
}

.list-icon{
    padding: 0px;
    li{
        list-style: none;
        position: relative;
        padding: 0.25em 0px 0.25em 20px;
        &:before{
            position: absolute;
            content: "";
            display: block;
            width: 12px;
            height: 12px;
            background-color: $blue;
            border-radius: 50%;
            top: 1em;
            margin-top: -6px;
            left: 0px;
        }
    }
}
.list-dib{
    margin: 0px;
    padding: 0px;
    letter-spacing: -.4em;
    li{
        letter-spacing: normal;
        display: inline-block;
        padding: 0px 5px 10px 5px;
    }
    &.type-emblem{
        @include media-breakpoint-down(sm) {
            li{
                max-width: 33.33%;
            }
        }
    }
}