﻿//
// Page
//

.breadcrumb{
	background-color: transparent;
	margin: 1em 0px;
	padding: 1.5em 0px 0px 0px;
	font-size: rem(12);
	&-item{
		white-space: nowrap;
		a,span{
			color: $body-color;
			font-weight: bold;
		}
		+ .breadcrumb-item{
			&:before{
				content: ">";
			}
		}
	}
}
body.page{
	.row{
		& > *{
			margin-bottom: 25px;
		}
	}
	.wrap-attendant-profile{
		@include media-breakpoint-down(sm) {
			max-width: 480px;
			margin: 0px auto;
		}
		@include media-breakpoint-down(xs) {
			max-width: 220px;
		}
	}
	.attendant-profile{
		text-align: center;
		&--name{
			margin: 1em 0px;
			font-size: rem(24);
			font-weight: bold;
			.kana{
				margin-top: 5px;
				padding: 0.1em 0.5em;
				display: block;
				text-align: center;
				color: #fff;
				font-weight: normal;
				font-size: rem(14);
			}
		}
		&--title{
			text-align: center;
			padding: 0.3em 0.5em;
			margin: 1.8em 0px 0.7em 0px;
			font-size: rem(16);
			font-weight: bold;
			border-bottom: 2px solid $gray-800;
		}
		&--text{
			font-size: rem(15);
			text-align: left;
			min-height: 4.5em;
			@include media-breakpoint-down(xs) {
				min-height: auto;
				min-height: initial;
			}
		}
		&.type-01{
			.attendant-profile--name{
				.kana{ background-color: #007ab7; }
			}
			.attendant-profile--title{
				border-bottom-color: #007ab7;
			}
		}
		&.type-02{
			.attendant-profile--name{
				.kana{ background-color: #fabe00; }
			}
			.attendant-profile--title{
				border-bottom-color: #fabe00;
			}
		}
		&.type-03{
			.attendant-profile--name{
				.kana{ background-color: #007d6e; }
			}
			.attendant-profile--title{
				border-bottom-color: #007d6e;
			}
		}
		&.type-04{
			.attendant-profile--name{
				.kana{ background-color: #ed7979; }
			}
			.attendant-profile--title{
				border-bottom-color: #ed7979;
			}
		}
	}
	.title-bus-schedule--sub{
		margin: 4em 0px 0.3em 0px;
		text-align: center;
		color: #1a2c54;
		font-weight: bold;
	}
	.title-bus-schedule{
		display: inline-block;
		font-size: rem(24);
		font-weight: bold;
		position: relative;
		margin: 0.5em 0px 2em 0px;
		padding: 0px 0px 0px 70px;
		&:before{
			width: 55px;
			height: 30px;
			position: absolute;
			top: 50%;
			left: 0px;
			margin-top: -15px;
		}
	}
	.wrap-schedule{
		max-width: 620px;
		margin: 0px auto 45px auto;
	}
}