/* utilities
-------------------------------------------------- */
hr{
    margin: 2em 0px 3em 0px;
}


.exhibitor-list--wrap{
    @include media-breakpoint-down(xs) {
        max-width: 380px;
        margin: 0px auto;
    }
}
.exhibitor-list{
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: 3em;
    &--item{
        width: 20%;
        padding: 0px 12px 24px 12px;
        color: $gray-800;
        text-decoration: none;
        @include media-breakpoint-down(sm) {
            width: 25%;
        }
        @include media-breakpoint-down(xs) {
            width: 50%;
        }
    }
    &--title{
        padding-top: 0.3em;
    }
}

.exhibitor-detail{
    @include media-breakpoint-down(xs) {
        justify-content: center;
        .col-sm-6{
            max-width: 454px;
        }
    }
    a{
        color: $gray-800;
        text-decoration: none;
        transition: all .25s ease-out;
        &:hover, &:focus{
            .exhibitor-detail--title{
                text-decoration: underline;
            }
        }
    }
    &--title{
        margin: 1em 0px 0.5em 0px;
        font-size: rem(18);
        font-weight: bold;
        color: $blue;
        .text-small{
            font-size: rem(15);
        }
    }
    &--body{
        font-size: rem(15);
    }
}

.exhibitor-vehicle--title{
    margin: 2em 0px 1em 0px;
    font-size: rem(20);
    text-align: center;
    color: $blue;
}
.exhibitor-vehicle{
    max-width: 780px;
    margin: 0px auto 70px auto;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &.type-center{
        justify-content: center;
    }
    li{
        list-style: none;
        width: calc(50% - 10px);
        border-top: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;
        margin-top: -1px;
        padding: 0.7em 0px;
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }
}