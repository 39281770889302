.footer{
    margin-top: 80px;
    &-inner{
        text-align: center;
        padding-bottom: 15px;
    }
}
body.home{
    .footer{
        margin-top: 30px;
    }
}
.copyright{
    margin: 0px;
    padding: 1em;
    text-align: center;
}