﻿//
// Home
//
.section{
	margin: 80px 0px;
	h2{
		@extend .h1;
	}
}
.section-mainvisual{
	text-align: center;
}
.section-ad{
	background-color: #dcdcdc;
	padding-top: 20px;
	padding-bottom: 0px;
}
.section-footer{
	margin-top: 30px;
	.container{
		max-width: 1000px;
	}
}

.section-sns{
	h2{
		margin-bottom: 1.5em;
	}
}

.twitter{
	border: 1px solid #dcdcdc;
}

.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style]{
    width: 100% !important;
}

.instagram{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	&-pic{
		width: 49%;
		margin-bottom: 2.2%;
	}
}

.sns-wrap{
	@include media-breakpoint-down(sm) {
		max-width: 300px;
		margin: 0px auto;
	}
}