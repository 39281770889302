.aligncenter{
    text-align: center;
}
.alignright{
    text-align: right;
}

.h1{
    margin: 2.5em 0px 0.3em 0px;
    text-align: center;
    font-size: rem(28);
    font-weight: bold;
    color: #1a2c54;
    &:after{
        content: "";
        margin: 10px auto 0px auto;
        display: block;
        width: 52px;
        height: 4px;
        background-color: $blue;
    }
}
.h2{
    margin: 1.5em 0px 2em 0px;
    font-size: rem(20);
    font-weight: bold;
    text-align: center;
    color: #1a2c54;
}

.box-important{
    border: 2px solid $pink;
    max-width: 940px;
    padding: 1em;
    margin: 0px auto 1.8em auto;
    text-align: center;
    color: $pink;
    font-size: rem(20);
    color: $pink;
}


body.page{
    h1{
        @extend .h1;
    }
    h2{
        @extend .h2;
    }
    &.access,
    &.contact,
    &.attendant{
        h1{
            margin-bottom: 2em;
        }
    }
    &.exhibitor{
        h1{
            margin-bottom: 1.3em;
        }
    }
    //h2
    .exhibitor--category{
        font-size: rem(20);
        color: #fff;
        margin: 2em  0px 1.5em;
        padding: 0.6em 1em;
        .alphabet{
            padding-left: 1em;
            font-size: rem(15);
        }
        &.type-yellow{
            background-color: #fabe00;
        }
        &.type-green{
            background-color: #007d6e;
        }
        &.type-pink{
            background-color: #ed7979;
        }
    }
}