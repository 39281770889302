.icon{
    &:before{
        content: "";
        display: block;
        background-position: center center;
        background-repeat: no-repeat;
    }
    &.type-gnav1:before{ background-image: url("#{$imgDir}/common/gnav1.png"); }
    &.type-gnav2:before{ background-image: url("#{$imgDir}/common/gnav2.png"); }
    &.type-gnav3:before{ background-image: url("#{$imgDir}/common/gnav3.png"); }
    &.type-gnav4:before{ background-image: url("#{$imgDir}/common/gnav4.png"); }
    &.type-gnav5:before{ background-image: url("#{$imgDir}/common/gnav5.png"); }
    &.type-gnav6:before{ background-image: url("#{$imgDir}/common/gnav6.png"); }
    &.type-gnav7:before{ background-image: url("#{$imgDir}/common/gnav7.png"); }
    &.type-shuttle-bus:before{ background-image: url("#{$imgDir}/access/icon-shuttle-bus.png"); }
}