﻿body{
	&:after{
		content: "";
		display: block;
		height: 37px;
		background-image: url("#{$imgDir}/common/img-decoration.png");
		background-position: center center;
		background-size: auto 100%;
	}
	// &.page{
	// 	&:after{
	// 		margin-top: 80px;
	// 	}
	// }
}

.container{
	max-width: $body-max-width + 100;
	padding-left: 50px;
	padding-right: 50px;
	@include media-breakpoint-down(sm) {
		padding-left: 15px;
		padding-right: 15px;
	}
}
img {
	max-width: 100%;
	height: auto;
	transition: $transition-base;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	// WordPress
	&.alignleft {
		float: left;
		margin-right: 1rem;
	}

	&.alignright {
		float: right;
		margin-left: 1rem;
	}

	&.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

a {
	@include hover-focus {
		img {
			opacity: .8;
		}
	}
}