//
// Header
//

// Layout
.header {
	&-inner {
		position: relative;
		@include media-breakpoint-up(md) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			nav {
				flex: 1 1 auto;
			}
		}
		@include media-breakpoint-down(xs) {
			padding: 0px;
		}
	}
}
body.page{
	.header {
		&:after{
			content: "";
			display: block;
			height: 37px;
			background-image: url("#{$imgDir}/common/img-decoration.png");
			background-position: center center;
			background-size: auto 100%;
		}
	}
}

// Logo
.header-logo {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 100%;
	display: flex;
	align-items: center;
	padding: 16px 0px;
	@include media-breakpoint-down(xs) {
		padding-left: 15px;
	}
}

// Button
.header-button-container {
	position: absolute;
	top: 20px;
	right: 20px;

	button {
		width: 55px;
		height: 44px;
		background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
		appearance: none;
		position: relative;
		span{
			display: block;
			width: 55px;
			height: 5px;
			background-color: #1c2d53;
			position: absolute;
			left: 0px;
			transition: all .25s ease-out;
			&:first-child{ top: 0px; }
			&:first-child+*{ top: 19px; }
			&:first-child+*+*{ top: 38px; }
		}
		&.is-active{
			span{
				&:first-child{ top: 19px; transform: rotate(35deg); }
				&:first-child+*{ display: none; }
				&:first-child+*+*{ top: 19px; transform: rotate(-35deg); }
			}
		}
	}
}

// Nav
.header-nav--wrap{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-left: 20px;
	@include media-breakpoint-down(xs) {
		display: block;
		background-color: #f2f0f1;
		text-align: center;
		padding: 10px 30px 50px 30px;
	}
}
.header-nav {
	list-style: none;
	margin: 0;
	padding: 8px 0px;
	height: 100%;
	flex-grow: 1;
	max-width: 600px;
	@include media-breakpoint-up(sm) {
		display: flex;
		align-items: stretch;
		justify-content: space-around;
	}
	@include media-breakpoint-down(xs) {
		padding: 0px;
	}
	&-item {
		@include media-breakpoint-down(xs) {
			border-bottom: 1px solid #c6c6c6;
		}
		.icon {
			display: block;
			padding: 8px;
			font-size: rem(14);
			font-weight: bold;
			color: #1a2c54;
			text-decoration: none;
			transition: all .25s ease-out;
			border-radius: 6px;
			&:before{
				content: "";
				display: block;
				width: 38px;
				height: 23px;
				margin: 0px auto 8px auto;
			}
			&:not(.disabled){
				&:hover{
					background-color: #d4eefc;
					@include media-breakpoint-down(xs) {
						background-color: transparent;
					}
				}
			}
		}
	}
}
.header-sns{
	display: flex;
	margin: 0px;
	padding: 0px 0px 0px 20px;
	@include media-breakpoint-down(xs) {
		padding: 30px 0px 0px 0px 0px;
		justify-content: center;
	}
	&-item{
		list-style: none;
		margin-left: 8px;
	}
}

a.disabled{
	pointer-events: none;
	opacity: 0.2;
}